<div class="home-container" >
  <div class="container-main">
    <app-search-panel [staticUrl]="staticUrl" [isHomePage]="true" [showPromoButton]="true" [name]="'Medical'"></app-search-panel><br/>
    <app-popular-location [popularLocations]="popularLocations"></app-popular-location>
    <app-collaborations></app-collaborations>
    <app-featured-on [pressList]="pressList"></app-featured-on>
    <app-why-dental></app-why-dental>
    <app-how-we-rate></app-how-we-rate>
    <hr class="hr" *ngIf="whyInfo"/>
    <app-why-departures [title]="''" [subtle]="''" [whyInfo]="whyInfo"></app-why-departures>
  </div>
</div>
