<div class="general-margin">
  <div class="row justify-content-center justify-content-md-center justify-content-lg-center justify-content-xl-center my-5">
    <h3 i18n="@@collaborations" (click)="onClickUrl()">Collaborations</h3>
  </div>
  <div class="row">
    <div class="col">
      <div class="press-list">
          <!-- <swiper class="swiper-containers" fxFlex="auto" [config]="config" [disabled]="disabled"
          (indexChange)="onIndexChange($event)" (swiperTransitionStart)="onSwiperEvent('transitionStart')"
          (swiperTransitionEnd)="onSwiperEvent('transitionEnd')"> -->
          <ng-container>
              <div class="collaborations">
                <!-- <a routerLink="article/medical-departures-announces-new-partnership-with-mastercard"> -->
                  <div class="press-list__item-link">
                    <img class="collaboration-image" src="{{ masterCardLogo }}" [alt]="'Logo of Master card'" loading="lazy"/>
                  </div>
                <!-- </a> -->
                <span style="width: 100px;"></span>
                <!-- <a routerLink="article/medical-departures-reserve-with-google"> -->
                  <div class="press-list__item-link">
                    <img class="collaboration-image" src="{{ reserveWithGoogleLogo }}" [alt]="'Logo of Reserve with Google'" loading="lazy"/>
                  </div>
                <!-- </a> -->
              </div>
              <!-- <div class="swiper-slide swiper-options-header"> -->
                  <!-- <div fxLayout="column">
                    <div class="press-list-card">
                      <a class="press-list__item-link" routerLink="article/dental-departures-announces-new-partnership-with-mastercard">
                        <img class="press-list__item-image" src="{{ masterCardLogo }}" [alt]="'Logo of Master card'" loading="lazy"/>
                      </a>
                    </div>
                  </div> -->
              <!-- </div> -->
          </ng-container>
      <!-- </swiper> -->
      </div>
    </div>
  </div>
</div>
