import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '@lib/features/language/language.service';
import { FooterHeaderDataItems, FooterItems, FooterSection } from '@lib/shared-core/layouts/footer-v2/footer-v2.component';
import { WhyDeparturesData } from '@lib/shared-core/layouts/why-departures/why-departures.component';
import { ICommon, ILanguage } from '../../../../../core/src/lib/common/common.models';
import { ApiService } from '../../../../../core/src/lib/data/core-api.service';
import { LogService } from '../../../../../core/src/lib/features/log/log.service';
import { I18nService } from '../../../../../core/src/lib/i18n/i18n.service';
import { MenuItem } from '../../../../../core/src/lib/models/menu-item.model';
import { MenuItem as MenuItemPrime } from 'primeng/api/menuitem';
import { IPromotion } from '../../modules/promotion/promotion.models';
import { IReview } from '../../modules/review/review.modes';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() common!: ICommon;
  footerHeaderDataItems: FooterHeaderDataItems | undefined;
  latestPromotions: IPromotion[] = [];
  latestReview!: IReview;
  languageLists: any[] = [];
  isNotAllowedPage: boolean = false;

  subtle: any = `As well as providing free access to our global lists of background-background checked dentists,
  we provide a range of other services and benefits to ensure your dental trip is both cost-effective
  and hassle-free, including`;

  whyInfo: WhyDeparturesData[] = [];


  constructor(
    private apiService: ApiService,
    private router: Router,
    private logService: LogService,
    private languageService: LanguageService) { }

  ngOnInit() {

    this.initPromotionsAndReviews();
    this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
      if (event instanceof NavigationStart) {
         if(!(!this.latestPromotions || !this.latestReview)) return ;
        this.initPromotionsAndReviews(event.url);
      }
    });


    this.whyInfo = [{
      title: $localize`:@@awesomeSaving:Awesome Savings.`,
      info: $localize`:@@awesomeSavingDescription:Save up to 70% compared to treatment at home.`,
      icon: 'fa fa-piggy-bank'
    },
    {
      title: $localize`:@@lowestPrices:Get the lowest prices.`,
      info: $localize`:@@lowestPricesDescription:Best price guaranteed.`,
      icon: 'ri-bank-card-line',
      link: '/guarantee'
    },
    {
      title: $localize`:@@maximumSelection:Maximum Selection.`,
      info: `Browse ` + this.common.stats.clinics + ` clinics in ` + this.common.stats.countries + ` countries`,
      icon: 'ri-award-line'
    },
    {
      title: $localize`:@@yourCurrency:Shop in your currency.`,
      info: $localize`:@@yourCurrencyDescription:Browse for the cost of treatment in your currency.`,
      icon: 'fa fa-money-bill'
    }];


    this.footerHeaderDataItems = {
      logo: 'assets/logo/md-logo.svg',
      description: 'The world’s largest online medical travel agency with more than 100,000 bookings to date.',
      footerSiteName: 'Medical Departures',
      awards: ['assets/badges/dd-gpc_logo_badge_large.png', 'assets/badges/Platinum_Trusted_Service_Award.png'],
      items: this.mapFooterSection(),
      socialMenu: [
        MenuItem.createSocialLink('https://www.facebook.com/medicaldepartures', 'fab fa-facebook'),
        MenuItem.createSocialLink('https://twitter.com/medicaldepartures', 'fab fa-twitter'),
        MenuItem.createSocialLink('https://www.youtube.com/user/Mymedicaldepartures/videos', 'fab fa-youtube'),
        MenuItem.createSocialLink('https://www.instagram.com/medical-departures/', 'fab fa-instagram'),
        MenuItem.createSocialLink('https://www.linkedin.com/company/dental-departures', 'fab fa-linkedin'),
        MenuItem.createSocialLink('https://pinterest.com/medicaldepartures', 'fab fa-pinterest')
      ],
      languageLists: this.languageLists.filter((data: any) => data.value != 'Turkey')
    }
  }

  initPromotionsAndReviews(url?: any) {
    const navigatedUrl = url ? url : this.router.url;
    this.isNotAllowedPage = navigatedUrl === '/';
    if (!this.isNotAllowedPage) {
      const commonHeaderItems$ = combineLatest([
        this.apiService.promotionLatestGET(),
        this.apiService.reviewsLatestGET()
      ]);
      commonHeaderItems$.pipe(untilDestroyed(this)).subscribe((data: any | any[]) => {
        const [promotions, reviews] = data;
        if(promotions)  {
          this.latestPromotions = promotions.promotions as IPromotion[];
        }
        if(reviews) {
          this.latestReview = reviews as IReview;
        }
      });
    }

  }


  /** Map Links to Current UI */
  mapFooterSection(): any[] {
    const footerHeaderDataItems: any[] = [];

    /**
     * Company Menu
     */
    const companyList: FooterItems[] = [
      MenuItem.createInternalLink($localize`:@@home:Home`, ''),
      MenuItem.createExternalLink($localize`:@@rewards:Rewards`, 'http://book.medicaldepartures.com/medical_departures_rewards/'),
      MenuItem.createInternalLink($localize`:@@termsOfServices:Terms of Services`, '/terms'),
      MenuItem.createInternalLink($localize`:@@policyTitle:Privacy Policy`, '/privacy'),
      MenuItem.createInternalLink($localize`:@@articles:Articles`, '/articles')
    ];

    const companySection: FooterSection = {
      title: $localize`:@@company:Company`,
      footerItems: companyList
    };

    /**
    * Location Menu
    */
    const locationList: FooterItems[] = [
      MenuItem.createInternalLink($localize`:@@popularCountries:Popular Countries`, '/popular-countries'),
      MenuItem.createInternalLink($localize`:@@popularCities:Popular Cities`, '/popular-cities'),
      MenuItem.createInternalLink($localize`:@@topClinic:Top Clinics`, '/top-clinics'),
      MenuItem.createInternalLink($localize`:@@addYourClinic:Add Your Clinic`, '/partner/signup')
    ];


    const locationSection: FooterSection = {
      title: $localize`:@@locations:Location`,
      footerItems: locationList
    };

    /**
     * Location Menu
     */
    const aboutUsList: FooterItems[] = [
      MenuItem.createInternalLink($localize`:@@about:About`, '/about'),
      MenuItem.createInternalLink($localize`:@@contact:Contact`, '/contact'),
      MenuItem.createInternalLink($localize`:@@collaborations:Collaborations`, '/collaborations-partnerships'),
      MenuItem.createInternalLink($localize`:@@faqs:FAQs`, '/about/faqs'),
      MenuItem.createInternalLink($localize`:@@mediaRoom:Media Room`, '/media-room'),
      MenuItem.createInternalLink($localize`:@@sitemaps:Sitemap`, '/sitemap'),
    ];


    const aboutSection: FooterSection = {
      title: $localize`:@@aboutUs:About Us`,
      footerItems: aboutUsList
    };

    footerHeaderDataItems.push(companySection);
    footerHeaderDataItems.push(locationSection);
    footerHeaderDataItems.push(aboutSection);
    return footerHeaderDataItems;

  }

  /**
  *
  * @param language path pass language code to change the current lange
  */
  changeCurrentLanguage(item: ILanguage) {
    this.languageService.changeLanguage(item.path);
  }

  /**
   *
   * @param data pass menu item, perform recursive call for child items to be mapped
   * @returns menu items for primeng
   */
  mapLanguageItemsToPrimeNg(currentLanguage: ILanguage, data: ILanguage[] | any[] | any): MenuItemPrime[] {
    const items = data.map((item: ILanguage) => ({
      label: item.value,
      icon: '',
      command: () => {
        this.changeCurrentLanguage(item);
      }
    }));
    return [{
      label: currentLanguage.value,
      icon: 'fas fa-caret-down',
      styleClass: 'language-wrapper',
      items
    }];
  }

}
